<template>
  <div class="m-2">
    <h1>Settings</h1>
    <b-form-group label="Default Link Prefix:" label-for="link-prefix">
      <b-form-input id="link-prefix" v-model="linkPrefix"></b-form-input>
    </b-form-group>
    <b-form-group label="Notifications:" label-for="email-notifications">
      <b-form-checkbox id="email-notifications" v-model="emailNotifications">
        Email Notifications
      </b-form-checkbox>
      Webhook URL:
      <b-form-input id="webhook-url" v-model="webhookUrl" placeholder="Webhook URL"></b-form-input>
    </b-form-group>

    <!-- Beta Section -->
    <div v-if="beta" class="mb-2">
      <h2>Beta</h2>
      <b-form-checkbox id="create-slides" v-model="createSlides">
        Create Slides
      </b-form-checkbox>
      <b-form-checkbox id="create-embed" v-model="createEmbed">
        Create Embed
      </b-form-checkbox>
      <b-form-checkbox id="create-series" v-model="createSeries">
        Create series
      </b-form-checkbox>
    </div>

    <b-button @click="saveSettings">Save</b-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      linkPrefix: this.$store.state.settings?.linkPrefix,
      emailNotifications: this.$store.state.settings?.emailNotifications,
      webhookUrl: this.$store.state.settings?.webhookUrl,
      beta: this.$store.state.settings?.beta,
      createSlides: this.$store.state.settings?.createSlides,
      createEmbed: this.$store.state.settings?.createEmbed,
      createSeries: this.$store.state.settings?.createSeries,
    };
  },
  methods: {
    saveSettings() {
      this.$store.saveSettings({
        linkPrefix: this.linkPrefix,
        emailNotifications: this.emailNotifications,
        webhookUrl: this.webhookUrl,
        createSlides: this.createSlides,
        createEmbed: this.createEmbed,
        createSeries: this.createSeries,
      });
    },
  },
};
</script>
